import React from 'react'

export default function Hero() {
    return (
        <section>
            <div className="container mx-auto lg:py-17 py-12">
                <div className="space-y-4 max-w-3xl mx-auto">
                    <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                        Good business should know every step of growth.<br />
                        That's why we are here.
                    </h1>
                    <p className="text-base lg:text-lg">Handdy is a division of Integra Global Solutions Corp. Handdy Apps is a suite of simple software applications built for businesses. They are built on the cloud using latest technologies. They are powerful yet ridiculously simple to use.</p>
                    <h1 className="text-xl lg:text-3xl text-heading-dark">Why was Handdy Timesheets built?</h1>
                    <p className="text-base lg:text-lg">Our parent company Integra operates from locations in multiple countries. Integra is a software services and business outsourcing company with large pool of employees whose productive working hours is related to the efficiency and revenue.</p>
                    <p className="text-base lg:text-lg">Integra needed an employee productivity monitoring system to effectively manage their in-house teams, work from home staff, staff working from different countries, on travel, on client site, logging in remotely, working different shifts, part-time staff and combination of these.</p>
                    <p className="text-base lg:text-lg">We wanted a simple time tracker and monitoring solution to accommodate all these types of employees. We wanted the solution to be effective, powerful, and yet simple to use. The result is Handdy timesheets. We have covered almost every possible employee work scenario. That is why Handdy timesheet is good enough for 99% of companies.</p>
                    <h1 className="text-xl lg:text-3xl text-heading-dark">Handdy products</h1>
                    <ul className="text-base lg:text-lg">
                        <li>Timesheets ‒ Employee monitoring and time tracking software for companies</li>
                        <li>Invoices ‒ Online invoicing software</li>
                        <li>Estimates ‒ Online estimation and quotation software</li>
                        <li>Accounts ‒ Simple basic accounting software</li>
                        <li>Handdy suite of applications are built on powerful and secure Google App Engine.</li>
                    </ul>
                    <p className="text-base lg:text-lg">Try these and you will love it.</p>
                </div>
            </div>
        </section>
    )
}
