import React from 'react'
import { Link } from 'gatsby'

export default function Teams() {
    return (
        <section className="bg-warning-soft rounded-tl-4xl rounded-tr-4xl relative">
            <img className="absolute top-28 hidden lg:block md:block" src="/pattern-1.svg"></img>
            <img className="absolute right-0 top-16 hidden lg:block md:block" src="/pattern-2.svg"></img>
            <div className="container mx-auto flex items-center flex-col lg:p-20 md:p-16 p-8">
                <h3 className="text-center">
                    Have any questions? Want some help getting started?
                </h3>
                <h1 className="text-dark-default text-2xl lg:text-5xl text-center md:text-4xl sm:text-3xl lg:leading-14">
                    Don’t be stranger, we always <br />
                    love to hear from you
                </h1>
                <Link to="/contact" className="flex items-center justify-center w-44 focus:outline-none font-semibold text-white text-sm mt-6 h-10 rounded-md bg-primary-orange box-shadow">
                    Contact Us
                </Link>
            </div>
        </section>
    )
}
