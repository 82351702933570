import React from 'react'

function Testimonials() {
    return (
        <section className="lg:pt-32 md:pt-24 sm:pt-16 rounded-t-3xl rounded-b-xl pt-4">
            <div className="container mx-auto space-y-4">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center">
                    Companies of all sizes love us
                </h1>
                <p className="text-body-light text-sm lg:text-lg text-center">
                    Rated five star and given <span className="text-primary font-bold">97% satisfaction rating</span> by customers
                </p>
                <div className="companies-wrapper py-7">
                    <img className="object-contain" src="/companies/01-slack.png"></img>
                    <img className="object-contain" src="/companies/02-hubspot.png"></img>
                    <img className="object-contain" src="/companies/03-square.png"></img>
                    <img className="object-contain" src="/companies/04-servicetitan.png"></img>
                    <img className="object-contain" src="/companies/05-cogni-ant.png"></img>
                    <img className="object-contain" src="/companies/06-grubhub.png"></img>
                </div>
                <div className="grid justify-items-center gap-8 lg:grid-cols-2 md:grid-cols-2">
                    <div className="card max-w-md grid gap-8">
                        <img className="object-contain" src="/companies/shopify.png"></img>
                        <p>
                            We were looking for a time-tracker and employee monitoring tool, that would work both online and offline. Handdy turns out the perfect solution for our needs.
                        </p>
                        <figure className="flex items-center space-x-4">
                            <img src="/avatar-1.png" />
                            <figcaption>
                                <h6 className="text-heading-dark font-bold lg:text-lg">Harold Tyler</h6>
                                <p className="text-body-muted">CEO, Atlantic International</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="card max-w-md grid gap-8">
                        <img className="object-contain" src="/companies/airbnb.png"></img>
                        <p>
                            We have moved all our staff to work from home after the COVID situation. We needed a tool to measure our staff tasks and monitor while they work from home.
                        </p>
                        <figure className="flex items-center space-x-4">
                            <img src="/avatar-2.png" />
                            <figcaption>
                                <h6 className="text-heading-dark font-bold lg:text-lg">Ryan Holmes</h6>
                                <p className="text-body-muted">Director, Trifecta Medical</p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
