import React from 'react'

export default function Teams() {
    return (
        <section>
            <div className="container mx-auto py-16">
                <h1 className="text-heading-dark text-2xl lg:text-5xl text-center md:text-4xl sm:text-3xl lg:leading-14">
                    Meet our amazing team
                </h1>
                <p className="text-center lg:text-xl md:text-lg mt-4">Make all happen</p>
                <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-8 lg:py-14 py-8">
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Rajesh Velayuthasamy.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Rajesh Velayuthasamy</p>
                            <p className="mt-3 text-body-muted font-semibold" >President</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Ganesh Ranganathan.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Palani  Kumar</p>
                            <p className="mt-3 text-body-muted font-semibold" >Chief Operations Officer</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Palani  Kumar" src="/team/Palani  Kumar.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Ganesh Ranganathan</p>
                            <p className="mt-3 text-body-muted font-semibold" >Founder and CEO</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Virgie Gregory" src="/team/Virgie Gregory.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Virgie Gregory</p>
                            <p className="mt-3 text-body-muted font-semibold" >SVP of Operations</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Jayden Frazier.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Jayden Frazier</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of Finance</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Clifford Lane" src="/team/Clifford Lane.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Clifford Lane</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of Marketing</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Stella Adkins" src="/team/Stella Adkins.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Stella Adkins</p>
                            <p className="mt-3 text-body-muted font-semibold" >Chief Learning Architect</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="VP of People" src="/team/Chad Blake.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Chad Blake</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of People</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Clifford Lane" src="/team/Clifford Lane.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Clifford Lane</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of Marketing</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Stella Adkins" src="/team/Stella Adkins-2.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Stella Adkins</p>
                            <p className="mt-3 text-body-muted font-semibold" >Chief Learning Architect</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Chad Blake" src="/team/Chad Blake.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Chad Blake</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of People</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Clifford Lane" src="/team/Clifford Lane.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Clifford Lane</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of Marketing</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Chad Blake.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Chad Blake</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of People</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Clifford Lane" src="/team/Clifford Lane.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Clifford Lane</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of Marketing</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img alt="Stella Adkins" src="/team/Stella Adkins-3.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Stella Adkins</p>
                            <p className="mt-3 text-body-muted font-semibold" >Chief Learning Architect</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Chad Blake-2.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Chad Blake</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of People</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Stella Adkins-4.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Stella Adkins</p>
                            <p className="mt-3 text-body-muted font-semibold" >Chief Learning Architect</p>
                        </figcaption>
                    </figure>
                    <figure className="flex items-center flex-col gap-6 w-full max-w-sm h-80 justify-center border border-solid border-primary-light rounded-xl">
                        <img src="/team/Chad Blake-3.png"></img>
                        <figcaption className="flex items-center flex-col">
                            <p className="font-bold lg:text-xl md:text-lg text-body-darker">Chad Blake</p>
                            <p className="mt-3 text-body-muted font-semibold" >VP of People</p>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
    )
}
