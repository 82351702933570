import React from 'react'

export default function Brands() {
    return (
        <section className="border-t border-b border-solid border-surface-light">
            <div className="container mx-auto lg:py-24 md:py-20 py-16">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center">
                    Companies of all sizes love us
                </h1>
                <p className="text-body-light text-sm lg:text-lg text-center mt-2">
                    Rated five star and given <span className="text-primary font-bold">97% satisfaction rating</span> by customers
                </p>
                <div className="grid lg:grid-rows-2 lg:grid-cols-4 grid-cols-2 gap-y-16 lg:py-12 py-8">
                    <img src="/companies/01-slack.png"></img>
                    <img src="/companies/02-hubspot.png"></img>
                    <img src="/companies/03-square.png"></img>
                    <img src="/companies/04-servicetitan.png"></img>
                    <img src="/companies/05-cogni-ant.png"></img>
                    <img src="/companies/06-grubhub.png"></img>
                    <img src="/companies/07-walpart.png"></img>
                    <img src="/companies/08-amazon.png"></img>
                </div>
                <div className="flex lg:justify-around md:justify-around lg:pt-12 pt-8 flex-wrap">
                    <figure className="flex items-baseline gap-5">
                        <img src="/experiance-1.svg"></img>
                        <figcaption className="self-start">
                            <h2 className="lg:text-6xl text-4xl font-bold text-heading-dark leading-13">10</h2>
                            <h3 className="lg:text-lg md:text-lg text-base mt-2">years experience</h3>
                        </figcaption>
                    </figure>
                    <figure className="flex items-baseline gap-5">
                        <img src="/experiance-2.svg"></img>
                        <figcaption className="self-start">
                            <h2 className="lg:text-6xl text-4xl font-bold text-heading-dark leading-13">2M+</h2>
                            <h3 className="lg:text-lg md:text-lg text-base mt-2">users</h3>
                        </figcaption>
                    </figure>
                    <figure className="flex items-baseline gap-5">
                        <img src="/experiance-3.svg"></img>
                        <figcaption className="self-start">
                            <h2 className="lg:text-6xl text-4xl font-bold text-heading-dark leading-13">70k</h2>
                            <h3 className="lg:text-lg md:text-lg text-base mt-2">companies</h3>
                        </figcaption>
                    </figure>

                </div>
                <div className="grid justify-items-center gap-8 lg:grid-cols-2 md:grid-cols-2 lg:pt-20 pt-16">
                    <div className="card max-w-md grid gap-8">
                        <img className="object-contain" src="/companies/shopify.png"></img>
                        <p>
                            We were looking for a time-tracker and employee monitoring tool, that would work both online and offline. Handdy turns out the perfect solution for our needs.
                        </p>
                        <figure className="flex items-center space-x-4">
                            <img src="/avatar-1.png" />
                            <figcaption>
                                <h6 className="text-heading-dark font-bold lg:text-lg">Harold Tyler</h6>
                                <p className="text-body-muted">CEO, Atlantic International</p>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="card max-w-md grid gap-8">
                        <img className="object-contain" src="/companies/airbnb.png"></img>
                        <p>
                            We have moved all our staff to work from home after the COVID situation. We needed a tool to measure our staff tasks and monitor while they work from home.
                        </p>
                        <figure className="flex items-center space-x-4">
                            <img src="/avatar-2.png" />
                            <figcaption>
                                <h6 className="text-heading-dark font-bold lg:text-lg">Ryan Holmes</h6>
                                <p className="text-body-muted">Director, Trifecta Medical</p>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    )
}
