import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/PageAbout/Hero"
import Brands from "../components/PageAbout/Brands"
import Testimonials from "../components/Testimonials"
import Teams from "../components/PageAbout/Teams"
import Question from "../components/PageAbout/Question"


const Index = () => (
    <Layout>
        <SEO title="About" />
        <Hero />
        <Brands />
        <Teams />
        <Question />
    </Layout>
)

export default Index
